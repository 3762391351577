<template>
  <div
    id="problem"
    class="pt-56"
  >
    <!-- TOOLBAR -->
    <Toolbar>
      <v-btn
        slot="btn"
        icon
        color="success"
        to="/service"
      >
        <Icon
          data="@icon/bottomNav/service.svg"
          width="24px"
          height="24px"
          class="icon--text"
        />
      </v-btn>
    </Toolbar>

    <!-- content -->
    <v-container>
      <v-row class="px-1">
        <v-col
          cols="12"
          class="pt-0"
        >
          <div
            v-for="(doc, index) in docs"
            :key="doc + index"
            class="doc mt-4"
          >
            <div class="doc__title primary--text d-flex">
              <p class="white--text question primary mb-0 text-center">
                <span>Q</span>
              </p>
              {{ doc.name }}
            </div>
            <div class="doc__content caption title--text mt-3 mb-6">
              <div
                v-for="(text, itemIndex) in doc.content.split('\r\n')"
                :key="text + itemIndex"
              >
                {{ text }}
              </div>
            </div>
            <v-divider class="divider" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Toolbar from '../../components/base/Toolbar.vue'
import { resStatus } from '../../utils/resUtils'

export default {
  components: {
    Toolbar,
  },

  data: () => ({
    docType: 'faq',
    docs: [],
  }),

  computed: {
    ...mapState({
      serviceUrl: state => state.serviceUrl,
    }),
  },

  mounted() {
    this.set_toolbar_title(this.$t('myCenter.commonProblem'))
    this.getProblemDoc(this.docType)
    this.getServiceUrl()
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_type_doc',
      'get_service_url',
      'show_alert',
    ]),

    getProblemDocSuccess({ docs }) {
      this.docs = docs
    },

    async getProblemDoc(type) {
      const { docs } = await this.get_type_doc(type)
      if (docs) this.getProblemDocSuccess(docs[0])
    },

    /**
     * 取得客服連結 (失敗)
     * @date 2021-08-17
     */
    getServiceUrlFail() {
      this.show_alert({ icon: 'fail' })
    },

    /**
     * 取得客服連結
     * @date 2021-08-17
     */
    getServiceUrl() {
      this.get_service_url()
    },

    /**
     * 另開客服連結頁面
     * @date 2021-08-17
     */
    toServicePage() {
      if (!this.serviceUrl) return this.getServiceUrlFail()
      window.open(this.serviceUrl, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped></style>
